import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/blog/blog_business_plan.jpg'
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from 'react-share';

var pageUrl = "";
if (typeof window !== 'undefined') {
    pageUrl = window.location.pathname
}
class SingleBlogTemplate extends React.Component{
  render(){
    return(
      <Layout>
      <SEO title="BLOG-The importance of a business transformation plan in implementing real change to any business or organization." />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">The importance of a business transformation plan in implementing real change to any business or organization</h1>
        </div>
      </section>

      <section id="p_blog" className="section single-blog">
          <div className="container">
              <div className="columns">
                  <div className="column is-2 share-box">
                        <h6 className="title">Share</h6>
                        <FacebookShareButton url={pageUrl} quote={"BLOG-The importance of a business transformation plan in implementing real change to any business or organization" + " | " + "Nuerex"}>
                            <span className="icon facebook"></span>
                        </FacebookShareButton>
                        <LinkedinShareButton url={pageUrl} windowWidth={750}  windowHeight={600}>
                            <span className="icon linkdin"></span>
                        </LinkedinShareButton>
                        <TwitterShareButton url={pageUrl} title={"BLOG-The importance of a business transformation plan in implementing real change to any business or organization" + " | " + "Nuerex"} >
                            <span className="icon twitter"></span>
                        </TwitterShareButton>
                  </div>
                  <div className="column is-10 content-area">
                      <p className="big-text">
                      When was the last time your organization underwent change or a significant transformation? 
                      Was this change easy to handle? And did it come with certain blowbacks or substantial improvements?</p>

                      <p>It's well known that whenever an organization goes through business transformation, it is striving to change for 
                          its betterment, ideally an increase in efficiency or returns for its stakeholders. However, when discussing 
                          the topic of transformation in business, two opposing ideas arise these are incremental change and business 
                          transformation. These translate to learning a new approach or step in the currently existing business model 
                          compared to overhauling and radically changing how employees engage with the workplace to produce value and results. 
                          This multiplies the importance of understanding factors that relate to resistance to change and how employees respond 
                          to change.</p>
                        <p>Important points to take note of include; identifying the strategy and vision, aligning human resources, relevant 
                            processes, and plans that are needed to reach that goal. The size of the transformation plan additionally affects 
                            its viability, as the goal is to change the way how a company conducts its business directly. Therefore it is of 
                            the utmost importance that all affected personnel are on board with the change. This includes the CEO and upper 
                            management down to base level employees. It is crucial always to keep everyone informed and updated as to how the 
                            organization will move forward.</p>
                        <p>Many professionals in the field agree that the best way to utilize business transformation management teams (BTM) 
                            is to build up and construct the best path to success. Holisticly this includes both a long term and short term 
                            strategy, understanding and having a clear vision, setting specific goals and objectives.</p>
                        
                        <div className="contact-box">
                            <h4>Talk with one of our consultants<br />
                            We're motivated and talented thinkers. Tell us the issue, and we'll find the solution.</h4>
                            <div className="button-area">
                            <Link to="/contact-us" className="button white">Get in contact</Link>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </section>

      </Layout>
    )
  }
}

export default SingleBlogTemplate
